import React, { useEffect, useContext, useState } from 'react';
import classnames from 'classnames';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import useAxios from '../hooks/useAxios';
import Loader from '../components/loader';
import Footer from '../components/footer';
import useRouter from '../hooks/useRouter';
import { Notifications } from '../hooks/useNotifications';
import useLicensed from '../hooks/useLicensed';

const useStyle = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: '#f1f1f1',
    },
    ['.MuiTouchRipple-root']: { display: 'none' },
  },
  root: {
    '@media (min-width: 780px)': {
      marginLeft: '250px',
    },
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F1F1F1',
    minHeight: '100%',
  },
  content: {
    flexGrow: 1,
    backgroundColor: '#F1F1F1',
  },
  disableBackground: {
    backgroundColor: 'transparent',
  },
  toolbar: theme.mixins.toolbar,
}));

const withPage = (Component, licenses = []) => {
  const WithPage = ({ ...props }) => {
    const style = useStyle();
    const router = useRouter();
    const [pathname, setPathname] = useState(router.pathname.valueOf());

    const isLicensed = useLicensed(licenses);

    useEffect(() => {
      if (isLicensed === false) {
        router.replace('/app/status/notfound');
      }
    }, [isLicensed]);

    useEffect(() => {
      if (router.pathname.valueOf() !== pathname.valueOf()) {
        setPathname(router.pathname.valueOf());
      }
    }, [router.pathname]);

    const [pingState, pingRequest] = useAxios(
      {
        url: '/web/_session/ping',
        method: 'GET',
      },
      false
    );

    useEffect(() => {
      if (!pingState.loading) {
        pingRequest();
      }
    }, [pathname]);

    const onHomePage = pathname.split('/').pop() === 'home';

    return (
      <div
        id='pageRoot'
        className={classnames({
          [style.root]: true,
          [style.disableBackground]: onHomePage,
        })}
      >
        <Notifications />
        <Paper
          id='pageContent'
          elevation={0}
          className={classnames({
            [style.content]: true,
            [style.disableBackground]: onHomePage,
          })}
        >
          <div className={style.toolbar} />
          {pingState.wasSuccessful && isLicensed ? (
            <Component {...props} />
          ) : (
            <Loader />
          )}
        </Paper>
        <Footer />
      </div>
    );
  };

  return WithPage;
};

export default withPage;
