import React from 'react';
import ReactDOM from 'react-dom';
import 'regenerator-runtime';

import App from './app';
import './locale/i18n';

ReactDOM.render(<App />, document.getElementById('app-root'));

if ((module as any).hot) {
  (module as any).hot.accept();
}
