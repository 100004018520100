import { useContext } from 'react';

import {
  LoggedInUserContext,
  loggedInUserActions,
} from '../contexts/loggedInUser';

const useLoggedInUser = () => {
  const loggedInUser = useContext(LoggedInUserContext);

  return {
    loggedInUserState: loggedInUser.state,
    loggedInUserDispatch: loggedInUser.dispatch,
    loggedInUserActions,
  };
};

export default useLoggedInUser;
