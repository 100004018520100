import { createContext } from 'react';
import {
  loadDataFromLocalStorage,
  saveDataToLocalStorage,
} from '../utility/localStorage';

export var loggedInUserInitialState = (() => {
  const userData = loadDataFromLocalStorage('userDetails', null);

  if (
    userData &&
    !(userData.name && userData.name && userData.email && userData.roles)
  ) {
    localStorage.removeItem('userDetails');
    return null;
  }

  return userData;
})();

export const loggedInUserActions = {
  LOGIN_USER: 'LOGIN_USER',
  LOGOUT_USER: 'LOGOUT_USER',
};

export const loggedInUserReducer = (
  state = loggedInUserInitialState,
  action
) => {
  switch (action.type) {
    case loggedInUserActions.LOGIN_USER:
      saveDataToLocalStorage('userDetails', action.userDetails);
      return {
        ...action.userDetails,
      };
    case loggedInUserActions.LOGOUT_USER:
      localStorage.removeItem('userDetails');
      localStorage.removeItem('i18nextLng');
      return null;
    default:
      return { ...state };
  }
};

export const LoggedInUserContext = createContext({
  state: loggedInUserInitialState,
  dispatch: null,
});
