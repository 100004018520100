import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RingLoader from 'react-spinners/RingLoader';

import useStyle from './loaderStyle';

const LoadingIndicator = ({ delay }) => {
  const style = useStyle();
  const [displayLoader, setDisplayLoader] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayLoader(true);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  if (displayLoader) {
    return (
      <div className={style.loader}>
        <RingLoader sizeUnit='px' size={200} color='#35BFC7' loading />
      </div>
    );
  }
  return <div />;
};

LoadingIndicator.propTypes = {
  delay: PropTypes.number.isRequired,
};

export default LoadingIndicator;
