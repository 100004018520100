import { useContext } from 'react';

import {
  TenantSettingsContext,
  tenantSettingsActions,
} from '../contexts/tenantSettings';

const useTenantSettings = () => {
  const tenantSettings = useContext(TenantSettingsContext);

  return {
    tenantSettingsState: tenantSettings.state,
    tenantSettingsDispatch: tenantSettings.dispatch,
    tenantSettingsActions,
  };
};

export default useTenantSettings;
