import { useMemo } from 'react';

import useLoggedInUser from './useLoggedInUser';

const useAuthentication = () => {
  const { loggedInUserState } = useLoggedInUser();

  const isAuthenticated = useMemo(() => {
    if (loggedInUserState) {
      return true;
    }
    return false;
  }, [loggedInUserState]);

  return isAuthenticated;
};

export default useAuthentication;
