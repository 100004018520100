import { createContext } from 'react';

export const companyMetadataInitialState = { update: true };

export const companyMetadataActions = {
  SET_COMPANY_METADATA: 'SET_COMPANY_METADATA',
};

export const companyMetadataReducer = (
  state = companyMetadataInitialState,
  action
) => {
  switch (action.type) {
    case companyMetadataActions.SET_COMPANY_METADATA:
      return {
        ...state,
        ...action.metadata,
        update: false,
      };
    default:
      return { ...state };
  }
};

export const CompanyMetadataContext = createContext({
  state: companyMetadataInitialState,
  dispatch: null,
});
