import makeStyles from '@material-ui/styles/makeStyles';

const useStyle = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  topBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#E1E1E1',
    boxShadow: 'none',
    color: '#505050',
  },
  logoLink: {
    textAlign: 'left',
    marginLeft: -theme.spacing(3),
    background: 'white',
    width: '250px',
    height: theme.spacing(8),
    padding: theme.spacing(1),
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  logo: {
    background: 'white',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  navigationLink: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  navigationLinkText: {
    width: '200px',
  },
}));

export default useStyle;
