import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  '@global': {
    body: {
      height: '100vh',
      overflowX: 'hidden',
    },
    '#app-root': {
      height: '100%',
    },
    '.MuiContainer-root': {
      height: '100%',
    },
  },
  footer: {
    position: 'relative',
    backgroundColor: '#E1E1E1',
    height: '60px',
    width: '200%',
    top: '60px',
    left: '-50%',
    zIndex: '0',
  },
  footerText: {
    fontSize: '0.8em',
    position: 'relative',
    textAlign: 'center',
    zIndex: '1',
    height: '60px',
    margin: '0px',
    lineHeight: '60px',
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
  },
  footerLinks: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
}));

export default useStyle;
