import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import useStyle from './mobileNavigationBarStyle';
import useAuthorisation from '../../hooks/useAuthorisation';
import useAuthentication from '../../hooks/useAuthentication';
import useCompanyMetadata from '../../hooks/useCompanyMetadata';
import useLicensed from '../../hooks/useLicensed';
import { pageNavigationList } from '../../utility/navigation';

import hamburgerIcon from '../../assets/icons/hamburger.svg';
import closeIcon from '../../assets/icons/close.svg';
import profileIcon from '../../assets/icons/profile.svg';

const NavigationLink = ({ navigationObject, setSidebarVisible, isSubMenu }) => {
  const style = useStyle();
  const isAuthorised = useAuthorisation(navigationObject.permissions);
  const isLicensed = useLicensed(navigationObject.licenses || [], navigationObject.hiddenCondition || []);
  const [translation] = useTranslation();

  if (!isLicensed) {
    return null;
  }

  if (!isAuthorised) {
    return null;
  }

  return (
    <Fragment>
      {navigationObject.link && (
        <Link
          className={style.navigationLinks}
          to={navigationObject.link}
          onClick={() => setSidebarVisible(false)}
        >
          <ListItem
            button
            className={isSubMenu ? style.subMenuItem : null}
            key={navigationObject.text}
          >
            {!isSubMenu && (
              <ListItemIcon>
                <img
                  className={style.navigationIcon}
                  src={navigationObject.icon}
                />
              </ListItemIcon>
            )}
            <ListItemText
              inset={isSubMenu}
              primaryTypographyProps={{
                className: isSubMenu ? style.subMenuText : null,
              }}
              primary={translation(navigationObject.text)}
            />
          </ListItem>
        </Link>
      )}
      {!navigationObject.link && (
        <ListItem
          button
          className={classnames(style.nonClickableLinks, {
            [style.subMenuItem]: isSubMenu,
          })}
          key={navigationObject.text}
        >
          {!isSubMenu && (
            <ListItemIcon>
              <img
                className={style.navigationIcon}
                src={navigationObject.icon}
              />
            </ListItemIcon>
          )}
          <ListItemText
            inset={isSubMenu}
            primaryTypographyProps={{
              className: isSubMenu ? style.subMenuText : null,
            }}
            primary={translation(navigationObject.text)}
          />
        </ListItem>
      )}
      {navigationObject.subMenus && !isSubMenu && (
        <List className={style.list}>
          {navigationObject.subMenus.map((navigationObject) => (
            <NavigationLink
              key={navigationObject.text}
              navigationObject={navigationObject}
              isSubMenu
            />
          ))}
        </List>
      )}
    </Fragment>
  );
};

NavigationLink.defaultProps = {
  isSubMenu: false,
};

NavigationLink.propTypes = {
  navigationObject: PropTypes.object.isRequired,
  setSidebarVisible: PropTypes.func.isRequired,
  isSubMenu: PropTypes.bool.isRequired,
};

const MobileNavigationBar = () => {
  const style = useStyle();
  const isAuthenticated = useAuthentication();
  const { companyMetadataState } = useCompanyMetadata();
  const [translation] = useTranslation();

  const [sidebarVisible, setSidebarVisible] = useState(false);

  const drawTopBar = (onNavigationMenu) => (
    <AppBar position='fixed' className={style.topBar}>
      <Toolbar className={style.topBarBorder}>
        <div className={style.topBarLogo}>
          <Link to='/app/index'>
            <img className={style.logo} src={companyMetadataState.logoUrl} />
          </Link>
        </div>
        <Button
          className={style.menuButton}
          onClick={() => setSidebarVisible(onNavigationMenu ? false : true)}
        >
          <img
            className={style.menuIcon}
            src={onNavigationMenu ? closeIcon : hamburgerIcon}
          />
        </Button>
      </Toolbar>
    </AppBar>
  );

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Fragment>
      {drawTopBar(false)}
      <Drawer
        anchor='right'
        open={sidebarVisible}
        onClose={() => setSidebarVisible(false)}
        className={style.drawer}
        classes={{ paper: style.drawerPaper }}
      >
        {drawTopBar(true)}
        <div className={style.toolbar} />
        <Typography className={style.menuTitle} variant='h5'>
          {translation('navigation.menuTitle')}
        </Typography>
        <List className={style.list}>
          {pageNavigationList.map((navigationObject) => (
            <NavigationLink
              key={navigationObject.text}
              navigationObject={navigationObject}
              setSidebarVisible={setSidebarVisible}
            />
          ))}
          <Link
            className={style.navigationLinks}
            to={'/app/profile'}
            onClick={() => setSidebarVisible(false)}
          >
            <ListItem button>
              <ListItemIcon>
                <img src={profileIcon} />
              </ListItemIcon>
              <ListItemText primary={translation('navigation.profile')} />
            </ListItem>
          </Link>
          <Link className={style.navigationLinks} to={'/app/logout'}>
            <ListItem button>
              <ListItemText primary={translation('navigation.logout')} />
            </ListItem>
          </Link>
        </List>
      </Drawer>
    </Fragment>
  );
};

export default MobileNavigationBar;
