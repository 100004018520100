import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import useStyle from './statusPageStyle';
import withPage from '../../higherOrderComponents/withPage';
import useRouter from '../../hooks/useRouter';

import errorIcon from '../../assets/icons/error.svg';

const StatusPage = () => {
  const style = useStyle();
  const router = useRouter();
  const [translation] = useTranslation();
  const statusType = router.query.status;

  const renderSomethingWentWrong = () => (
    <>
      <Helmet>
        <title>
          {translation('statusPage.heading')} - {translation('common.snobalCloud')}
        </title>
      </Helmet>
      <Grid container justify='center'>
        <img className={style.icon} src={errorIcon} />
        <Typography className={style.text} variant='h3'>
          {translation('statusPage.heading')}
        </Typography>

        <Button
          onClick={() => router.replace('/app/index')}
          className={style.button}
          variant='contained'
          color='primary'
        >
          {translation('statusPage.takeMeHome')}
        </Button>
      </Grid>
    </>
  );

  const renderPageNotFound = () => (
    <>
      <Helmet>
        <title>
          {translation('statusPage.heading')} - {translation('common.snobalCloud')}
        </title>
      </Helmet>
      <Grid container justify='center'>
        <img className={style.icon} src={errorIcon} />
        <Typography className={style.text} variant='h3'>
          {translation('statusPage.heading')}
        </Typography>
        <Typography className={style.text}>
          {translation('statusPage.pageNotFound')}
        </Typography>
        <Button
          onClick={() => router.replace('/app/index')}
          className={style.button}
          variant='contained'
          color='primary'
        >
          {translation('statusPage.takeMeHome')}
        </Button>
      </Grid>
    </>
  );

  switch (statusType) {
    case 'notfound':
      return renderPageNotFound();

    default:
      return renderSomethingWentWrong();
  }
};

export default withPage(StatusPage);
