import React, { useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import theme from './theme';
import Routes from '../routes';
import NavigationTopBar from '../components/navigationTopBar';
import NavigationSideBar from '../components/navigationSideBar';
import MobileNavigationBar from '../components/mobileNavigationBar';
import ErrorBoundary from '../components/errorBoundary';
import ConfirmPageNavigation from '../components/confirmPageNavigation';
import ConfigurationLoader from '../components/configurationLoader';
import {
  GlobalStoreContext,
  globalStoreReducer,
  globalStoreInitialState,
} from '../contexts/globalStore';
import {
  LoggedInUserContext,
  loggedInUserReducer,
  loggedInUserInitialState,
} from '../contexts/loggedInUser';
import {
  CompanyMetadataContext,
  companyMetadataReducer,
  companyMetadataInitialState,
} from '../contexts/companyMetadata';
import {
  TenantSettingsContext,
  tenantSettingsReducer,
  tenantSettingsInitialState,
} from '../contexts/tenantSettings';

import favicon from '../assets/icons/snobal-favicon-32.png';

const App = () => {
  const [globalStore, globalStoreDispatch] = useReducer(
    globalStoreReducer,
    globalStoreInitialState
  );
  const [loggedInUser, loggedInUserDispatch] = useReducer(
    loggedInUserReducer,
    loggedInUserInitialState
  );
  const [companyMetadata, companyMetadataDispatch] = useReducer(
    companyMetadataReducer,
    companyMetadataInitialState
  );
  const [tenantSettings, tenantSettingsDispatch] = useReducer(
    tenantSettingsReducer,
    tenantSettingsInitialState
  );

  const matches = useMediaQuery('(max-width: 780px)');

  return (
    <>
      <Helmet>
        <link rel='icon' type='image/png' href={favicon} sizes='32x32' />
      </Helmet>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <GlobalStoreContext.Provider
          value={{ state: globalStore, dispatch: globalStoreDispatch }}
        >
          <LoggedInUserContext.Provider
            value={{ state: loggedInUser, dispatch: loggedInUserDispatch }}
          >
            <CompanyMetadataContext.Provider
              value={{
                state: companyMetadata,
                dispatch: companyMetadataDispatch,
              }}
            >
              <TenantSettingsContext.Provider
                value={{
                  state: tenantSettings,
                  dispatch: tenantSettingsDispatch,
                }}
              >
                <BrowserRouter
                  getUserConfirmation={(message, callback) =>
                    ConfirmPageNavigation(message, callback)
                  }
                >
                  <ErrorBoundary>
                    <Container maxWidth={false}>
                      {matches && <MobileNavigationBar />}
                      {!matches && (
                        <>
                          <NavigationTopBar />
                          <NavigationSideBar />
                        </>
                      )}
                      {!!loggedInUser && <ConfigurationLoader />}
                      <Routes />
                    </Container>
                  </ErrorBoundary>
                </BrowserRouter>
              </TenantSettingsContext.Provider>
            </CompanyMetadataContext.Provider>
          </LoggedInUserContext.Provider>
        </GlobalStoreContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default App;
