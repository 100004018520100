import makeStyles from '@material-ui/styles/makeStyles';

const useStyle = makeStyles({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
});

export default useStyle;
