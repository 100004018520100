export const defaultEnvironments = {
  ENV_HR: {
    name: 'Reception Room',
    id: 'ENV_HR',
    thumbnail:
      'https://s3.ap-southeast-2.amazonaws.com/public.snobal.net/tenant-media/SnobalVR_Reception.jpg',
    displays: {
      Main: { id: 'Main', description: 'Main Display', limit: 1 },
      A: { id: 'A', description: 'Center' },
      B: { id: 'B', description: 'Left' },
      C: { id: 'C', description: 'Right' },
    },
  },
  ENV_AMPT: {
    name: 'Summit Amphitheatre',
    id: 'ENV_AMPT',
    thumbnail:
      'https://s3.ap-southeast-2.amazonaws.com/public.snobal.net/tenant-media/SnobalVR_Ampitheatre.jpg',
    displays: {
      A: { id: 'A', description: 'Center' },
    },
  },
  ENV_CR: {
    name: 'Command Room',
    id: 'ENV_CR',
    thumbnail:
      'https://s3.ap-southeast-2.amazonaws.com/public.snobal.net/tenant-media/SnobalVR_Commandroom.jpg',
  },
  ENV_IR: {
    name: 'Infinity Room',
    id: 'ENV_IR',
    thumbnail:
      'https://s3.ap-southeast-2.amazonaws.com/public.snobal.net/tenant-media/SnobalVR_ModelRoom.jpg',
  },
  ENV_HB: {
    name: 'Reception Breakout',
    id: 'ENV_HB',
    thumbnail:
      'https://s3.ap-southeast-2.amazonaws.com/public.snobal.net/tenant-media/SnobalVR_Reception_Breakout.jpg',
  },
  ENV_AMPB: {
    name: 'Amphitheatre Breakout',
    id: 'ENV_AMPB',
    thumbnail:
      'https://s3.ap-southeast-2.amazonaws.com/public.snobal.net/tenant-media/SnobalVR_Ampitheatre_Breakout.jpg',
  },
  ENV_CB: {
    name: 'Command Breakout',
    id: 'ENV_CB',
    thumbnail:
      'https://s3.ap-southeast-2.amazonaws.com/public.snobal.net/tenant-media/SnobalVR_Command_Breakout.jpg',
  },
  ENV_AR: {
    name: 'Activities Room',
    id: 'ENV_AR',
    thumbnail:
      'https://s3.ap-southeast-2.amazonaws.com/public.snobal.net/tenant-media/SnobalVR_ActivitesRoom.jpg',
  },
};