import { createMuiTheme } from '@material-ui/core/styles';

const primaryColour = '#0771A6';
const secondaryColour = '#35BFC7';

const primaryTextColor = '#505050';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: `${primaryColour}`,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: `${secondaryColour}`,
    },
    text: {
      primary: `${primaryTextColor}`,
      light: '#7A7878',
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        maxWidth: '1500px',
      },
    },
    MuiFormLabel: {
      root: {
        fontStyle: 'italic',
        '&$focused': {
          color: null,
        },
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: 'white',
        borderRadius: 5,
      },
      input: {
        '&::placeholder': {
          fontStyle: 'italic',
        },
      },
    },
    MuiInput: {
      underline: {
        '&::before': {
          borderColor: `${primaryColour}`,
        },
        '&::after': {
          borderColor: `${primaryColour}`,
        },
        '&:hover:not(.Mui-disabled)::before': {
          borderColor: `${primaryColour}`,
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '5px',
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: `${secondaryColour}`,
        },
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        border: `solid 1px ${primaryColour}`,
        borderLeft: 'none',
        // width: '100%',
        backgroundColor: '#E1E1E1',
        '&.Mui-selected': {
          backgroundColor: 'white',
        },
        '&:first-of-type': {
          borderTopLeftRadius: '5px',
          borderLeft: `solid 1px ${primaryColour}`,
        },
        '&:last-of-type': {
          borderTopRightRadius: '5px',
        },
      },
    },
    MuiToolbar: {
      root: {
        '@media (max-width: 780px)': {
          alignItems: 'stretch',
        },
      },
      gutters: {
        '@media (max-width: 780px)': {
          paddingLeft: '0px',
          paddingRight: '0px',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'white',
          borderColor: `${primaryColour}`,
          borderWidth: 1,
          borderRadius: 5,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$notchedOutline': {
          borderColor: `${primaryColour}`,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: `${primaryColour}`,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderColor: `${primaryColour}`,
          },
        },
        '&$focused $notchedOutline': {
          borderColor: `${primaryColour}`,
        },
      },
      notchedOutline: {
        borderColor: `${primaryColour}`,
        borderWidth: 1,
        borderRadius: 5,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '1em',
      },
    },
    MuiDialog: {
      container: {
        padding: '20px',
      },
    },
    MuiDialogTitle: {
      root: {
        textAlign: 'left',
      },
    },
    MuiDialogContentText: {
      root: {
        textAlign: 'left',
      },
    },
    MuiListSubheader: {
      root: {
        pointerEvents: 'none',
      },
    },
    MuiAccordion: {
      rounded: {
        borderRadius: '5px',
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: '8px',
        '&.Mui-expanded': {
          minHeight: '0px',
        },
      },
      content: {
        margin: '0px',
        '&.Mui-expanded': {
          margin: '0px',
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        flexDirection: 'column',
        margin: '0px',
        padding: '0px',
      },
    },
    MuiListItemText: {
      inset: {
        paddingLeft: '40px',
      },
    },
  },
});

export default theme;
