import makeStyles from '@material-ui/styles/makeStyles';

const useStyle = makeStyles((theme) => ({
  icon: {
    marginTop: theme.spacing(5),
    width: '400px',
  },
  text: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(4),
    width: '250px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default useStyle;
