import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import useAuthentication from '../../hooks/useAuthentication';
import useAuthorisation from '../../hooks/useAuthorisation';

const PrivateRoute = ({ component: Component, permissions, ...rest }) => {
  const isAuthenticated = useAuthentication();
  const isAuthorised = useAuthorisation(permissions);

  const RenderPrivateRoute = (props) => {
    if (isAuthenticated) {
      if (isAuthorised) {
        return <Component {...props} />;
      }

      return (
        <Redirect
          to={{
            pathname: '/app/status/notfound',
            state: { from: props.location },
          }}
        />
      );
    }

    return (
      <Redirect
        to={{
          pathname: '/app/login',
          state: { from: props.location },
        }}
      />
    );
  };

  RenderPrivateRoute.propTypes = {
    location: PropTypes.string.isRequired,
  };

  return <Route {...rest} render={RenderPrivateRoute} />;
};

PrivateRoute.defaultProps = {
  permissions: [],
};

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export default PrivateRoute;
