import { createContext } from 'react';
import { dynamicStringSort } from '../utility/utility';

export type TTenantSettingsState = {
  languages: {
    primary: {
      iso6391: string;
      name: string;
    };
    secondary: any[];
    list: {
      iso6391: string;
      name: string;
    }[];
    update: boolean;
  };
  licensing: {};
  environments: {};
  tags: any;
};

export const tenantSettingsInitialState: TTenantSettingsState = {
  languages: {
    primary: { iso6391: 'en', name: 'English' },
    secondary: [],
    list: [{ iso6391: 'en', name: 'English' }],
    update: true,
  },
  licensing: {},
  environments: {},
  tags: null,
};

export const tenantSettingsActions = {
  SET_TENANT_LANGUAGES: 'SET_TENANT_LANGUAGES',
  SET_LICENSING: 'SET_LICENSING',
  SET_TAGS: 'SET_TAGS',
  SET_ENVIRONMENTS: 'SET_ENVIRONMENTS',
};

export const tenantSettingsReducer = (
  state = tenantSettingsInitialState,
  action: any
) => {
  switch (action.type) {
    case tenantSettingsActions.SET_TENANT_LANGUAGES: {
      const languageList = action.languages.secondaries
        .concat([action.languages.primary])
        .sort(dynamicStringSort('name'));
      return {
        ...state,
        languages: {
          primary: action.languages.primary,
          secondary: action.languages.secondaries,
          list: languageList,
          apiCall: false,
        },
      };
    }

    case tenantSettingsActions.SET_LICENSING:
      return {
        ...state,
        licensing: {
          ...state.licensing,
          ...action.licenses,
        },
      };

    case tenantSettingsActions.SET_TAGS:
      return {
        ...state,
        tags: {
          ...state.tags,
          ...action.tags,
        },
      };

    case tenantSettingsActions.SET_ENVIRONMENTS:
      return {
        ...state,
        environments: action.environments,
      };

    default:
      return { ...state };
  }
};

export const TenantSettingsContext = createContext({
  state: tenantSettingsInitialState,
  dispatch: null,
});
