import React, { ErrorInfo, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import StatusPage from '../../pages/statusPage';

interface Props {
  children: ReactNode;
  history: RouteComponentProps['history'];
}

interface State {
  error: Error;
  errorInfo: ErrorInfo;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };

    this.props.history.listen(() => {
      if (this.state.error) {
        this.setState({ error: null, errorInfo: null });
      }
    });
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.errorInfo) {
      return <StatusPage />;
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary as any);
