import userIcon from '../assets/icons/user.svg';
import virtualRealityIcon from '../assets/icons/virtual-reality.svg';
import facilityIcon from '../assets/icons/facility.svg';
import xrManagementIcon from '../assets/icons/xr-management.svg';
// import peopleIcon from '../assets/icons/attendee.svg';
import homeIcon from '../assets/icons/home.svg';
import presentationIcon from '../assets/icons/presentation.svg';

export const pageNavigationList = [
  {
    text: 'Home',
    link: '/app/home',
    icon: homeIcon,
    permissions: ['any'],
    subMenus: [
      {
        text: 'navigation.dashboard',
        icon: null as string,
        link: '/app/dashboard',
        permissions: ['view-reports'],
        licenses: ['xr-management'],
      },
    ],
  },
  {
    text: 'VR Applications',
    icon: xrManagementIcon,
    link: null as string,
    permissions: [
      'view-presentations',
      'edit-presentations',
      'view-media',
      'manage-media',
      'view-sessions',
      'edit-sessions',
      'view-reports',
      'fetch-attendee-list',
      'edit-sessions',
      'view-tenant',
      'manage-tenant',
    ],
    licenses: ['xr-management'],
    subMenus: [
      {
        text: 'navigation.applications',
        icon: null as string,
        link: '/app/appregistry',
        permissions: ['any'],
        licenses: ['application-authoring'],
      },
      {
        text: 'navigation.attendees',
        icon: null as string,
        link: '/app/attendees',
        permissions: ['fetch-attendee-list', 'edit-sessions'],
        licenses: ['xr-management'],
      },
      {
        text: 'Join a meeting',
        link: '/app/joinameeting', //Placeholder for now
        permissions: ['any'],
      },
      {
        text: 'Rooms',
        link: '/app/rooms',
        permissions: ['any'],
      },
      {
        text: 'Reporting',
        icon: null as string,
        link: '/app/applicationReports',
        permissions: ['any'],
        licenses: ['application-authoring'],
      },
      // {
      //   text: 'navigation.dashboard',
      //   icon: null as string,
      //   link: '/app/dashboard',
      //   permissions: ['view-reports'],
      //   licenses: ['xr-management'],
      // },
      // {
      //   text: 'navigation.presentations',
      //   icon: null as string,
      //   link: '/app/presentations',
      //   permissions: ['view-presentations', 'edit-presentations'],
      //   licenses: ['xr-management'],
      //   hiddenCondition: [
      //     { 'xr-management': true },
      //     { 'application-authoring': true },
      //   ],
      // },
      // {
      //   text: 'navigation.sessions',
      //   icon: null as string,
      //   link: '/app/sessions#inProgress',
      //   permissions: ['view-sessions', 'edit-sessions'],
      //   licenses: ['xr-management'],
      // },
      {
        text: 'navigation.reports',
        icon: null as string,
        link: '/app/reports',
        permissions: ['view-reports'],
        licenses: ['xr-management'],
        hiddenCondition: [
          { 'xr-management': true },
          { 'application-authoring': true },
        ],
      },
      {
        text: 'navigation.screenshots',
        icon: null as string,
        link: '/app/screenshots',
        permissions: ['view-media', 'manage-media'],
        licenses: ['xr-management'],
        hiddenCondition: ['screenshots'],
      },
    ],
  },
  {
    text: 'Content Authoring',
    icon: presentationIcon,
    link: null as string,
    permissions: ['any'],
    licenses: ['application-authoring'],
    subMenus: [
      // {
      //   text: 'navigation.applications',
      //   icon: null as string,
      //   link: '/app/appRegistry',
      //   permissions: ['any'],
      //   licenses: ['application-authoring'],
      // },
      {
        text: 'navigation.presentations',
        icon: null as string,
        link: '/app/presentations',
        permissions: ['any'],
        licenses: ['application-authoring'],
      },
      {
        text: 'navigation.media',
        icon: null as string,
        link: '/app/media',
        permissions: ['view-media', 'manage-media'],
        licenses: ['xr-management'],
      },
      //TODO: will be defined later
      // {
      //   text: 'navigation.reportingExperiences',
      //   icon: null as string,
      //   link: '/app/authoring-experience',
      //   permissions: ['any'],
      //   licenses: ['application-authoring'],
      // },
    ],
  },
  // {
  //   text: 'navigation.reporting',
  //   icon: userIcon,
  //   link: null as string,
  //   permissions: ['any'],
  //   licenses: ['application-authoring'],
  //   subMenus: [
  //     {
  //       text: 'navigation.reportingApplications',
  //       icon: null as string,
  //       link: '/app/applicationReports',
  //       permissions: ['any'],
  //       licenses: ['application-authoring'],
  //     },
  //TODO: will be defined later
  // {
  //   text: 'navigation.spherePresentation',
  //   icon: null,
  //   link: '/app/presentationReports',
  //   permissions: ['any'],
  //   licenses: ['application-authoring'],
  // },
  // {
  //   text: 'navigation.reportingExperiences',
  //   icon: null,
  //   link: '/app/experienceReports',
  //   permissions: ['any'],
  //   licenses: ['application-authoring'],
  // },
  // {
  //   text: 'navigation.reportingCustom',
  //   icon: null,
  //   link: '/app/customReports',
  //   permissions: ['any'],
  //   licenses: ['application-authoring'],
  // },
  //   ],
  // },
  // {
  //   text: 'Meetings',
  //   permissions: ['any'],
  //   icon: peopleIcon,
  //   subMenus: [
  //     {
  //       text: 'Host a meeting',
  //       link: '/app/hostameeting', //Placeholder for now
  //       permissions: ['any'],
  //     },
  //     {
  //       text: 'Rooms',
  //       link: '/app/rooms',
  //       permissions: ['any'],
  //     },
  //   ],
  // },
  // {
  //   text: 'navigation.facilitiesManagement',
  //   icon: facilityIcon,
  //   link: null,
  //   permissions: ['view-facilities', 'manage-facilities'],
  //   licenses: ['facility-management'],
  //   subMenus: [
  //     {
  //       text: 'navigation.facilities',
  //       icon: null,
  //       link: '/app/facilities',
  //       permissions: ['view-facilities', 'manage-facilities'],
  //       licenses: ['facility-management'],
  //     },
  //     {
  //       text: 'navigation.zones',
  //       icon: null,
  //       link: '/app/zones',
  //       permissions: ['view-facilities', 'manage-facilities'],
  //       licenses: ['facility-management'],
  //     },
  //     {
  //       text: 'navigation.assets',
  //       icon: null,
  //       link: '/app/assets',
  //       permissions: ['view-facilities', 'manage-facilities'],
  //       licenses: ['facility-management'],
  //     },
  //     {
  //       text: 'navigation.documents',
  //       icon: null,
  //       link: '/app/documents',
  //       permissions: ['view-facilities', 'manage-facilities'],
  //       licenses: ['facility-management'],
  //     },
  //   ],
  // },
  {
    text: 'navigation.deviceManagement',
    icon: virtualRealityIcon,
    link: null,
    permissions: ['view-devices', 'manage-devices'],
    licenses: ['xr-management', 'mobile-device-management'],
    subMenus: [
      {
        text: 'Overview',
        icon: null,
        link: '/app/devices',
        permissions: ['view-devices', 'manage-devices'],
        licenses: ['xr-management', 'mobile-device-management'],
      },
      // {
      //   text: 'navigation.applications',
      //   icon: null,
      //   link: '/app/applications',
      //   permissions: ['view-devices', 'manage-devices'],
      //   licenses: ['mobile-device-management'],
      // },
      {
        text: 'navigation.deviceProfiles',
        icon: null,
        link: '/app/deviceProfiles',
        permissions: ['view-devices', 'manage-devices'],
        licenses: ['mobile-device-management'],
      },
    ],
  },
  {
    text: 'navigation.administration',
    icon: userIcon,
    link: null as string,
    permissions: ['any'],
    subMenus: [
      {
        text: 'navigation.users',
        icon: null as string,
        link: '/app/users',
        permissions: ['view-users', 'manage-users'],
      },
      {
        text: 'navigation.support',
        icon: null as string,
        link: '/app/support',
        permissions: ['any'],
      },
      {
        text: 'navigation.settings',
        icon: null,
        link: '/app/settings',
        permissions: ['view-tenant', 'manage-tenant'],
      },
      {
        text: 'Configurations',
        icon: null as string,
        link: '/app/settings/configurations',
        permissions: ['view-tenant', 'manage-tenant'],
        hiddenCondition: ['tenant-tags'],
      },
      // {
      //   text: 'Discover',
      //   icon: null as string,
      //   link: '/app/discover',
      // },
    ],
  },
  {
    text: 'Discover Snobal',
    icon: facilityIcon,
    link: '/app/discover',
  },
];
