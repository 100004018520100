import { useMemo } from 'react';

import useLoggedInUser from './useLoggedInUser';

const useAuthorisation = (permissions: String[]) => {
  const { loggedInUserState } = useLoggedInUser();
  const userPermissions = loggedInUserState
    ? loggedInUserState.permissions
    : [];

  if (userPermissions.includes('superuser')) {
    return true;
  }

  const hasPermission = useMemo(() => {
    let authorised = false;
    if (permissions) {
      permissions.forEach((permission) => {
        if (permission === 'any' || userPermissions.includes(permission)) {
          authorised = true;
          return;
        }
      });

      return authorised;
    }
  }, [loggedInUserState, permissions]);

  return hasPermission;
};

export default useAuthorisation;
