import makeStyles from '@material-ui/styles/makeStyles';

const useStyle = makeStyles((theme) => ({
  topBar: {
    boxShadow: 'none',
  },
  topBarLogo: {
    background: 'white',
    width: '100%',
    height: '72px',
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
  },
  logo: {
    background: 'white',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  menuButton: {
    background: `linear-gradient(346deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 100%)`,
    borderRadius: '0px',
    float: 'right',
    width: '30px',
  },
  menuIcon: {
    width: '100%',
    padding: theme.spacing(1),
  },
  drawer: {
    width: '100%',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '100%',
    background: `linear-gradient(346deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 100%)`,
  },
  menuTitle: {
    marginTop: theme.spacing(4),
    padding: `0px ${theme.spacing(4)}px`,
    color: 'white',
  },
  list: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  navigationLinks: {
    color: 'white',
    textDecoration: 'none',
  },
  nonClickableLinks: {
    color: 'white',
    textDecoration: 'none',
    cursor: 'default',
  },
  navigationIcon: {
    width: '20px',
    maxHeight: '20px',
  },
  subMenuItem: {
    paddingTop: '0px',
    paddingBottom: '0px',
    marginLeft: theme.spacing(1),
  },
  subMenuText: {
    fontSize: '1em',
  },
  toolbar: theme.mixins.toolbar,
}));

export default useStyle;
