import { useMemo } from "react";

import useTenantSettings from "./useTenantSettings";

interface TenantSettings {
  licensing: any;
}

const useLicensed = (licenses: string[], hiddenCondition?: string[]) => {
  const tenantSettingsState: TenantSettings = useTenantSettings()
    .tenantSettingsState;

  const isLicensed = useMemo(() => {
    let licensed = true;
    if (hiddenCondition && hiddenCondition.length > 0) {
      let checkHidden = true;
      hiddenCondition.map((condition) => {
        if (tenantSettingsState.licensing[Object.keys(condition)[0]]?.enabled !== Object.values(condition)[0]) {
          checkHidden = false;
        }
      });
      if (checkHidden) {
        return false;
      }
    }

    for (const license of licenses) {
      if (tenantSettingsState.licensing[license] === undefined) {
        licensed = undefined;
      } else if (
        licensed !== undefined &&
        tenantSettingsState.licensing[license].enabled === false
      ) {
        licensed = false;
      }
    }

    return licensed;
  }, [tenantSettingsState, licenses]);

  return isLicensed;
};

export default useLicensed;
