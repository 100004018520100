import React, { Fragment, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import useStyle from './navigationSideBarStyle';
import useAuthentication from '../../hooks/useAuthentication';
import useAuthorisation from '../../hooks/useAuthorisation';
import useLicensed from '../../hooks/useLicensed';
import useCompanyMetadata from '../../hooks/useCompanyMetadata';
import { pageNavigationList } from '../../utility/navigation';
import useTenantSettings from '../../hooks/useTenantSettings';

import poweredBySnobalLogo from '../../assets/logos/powered-by-snobal.png';

const NavigationLink = ({
  navigationObject,
  isSubMenu,
  collapseNavigationList,
  setCollapseNavigationList,
  parentName,
}) => {
  const style = useStyle();
  const { tenantSettingsState } = useTenantSettings();
  const { companyMetadataState } = useCompanyMetadata();
  const isAuthorised = useAuthorisation(navigationObject.permissions); 
  const isLicensed = useLicensed(
    navigationObject.licenses || [],
    navigationObject.hiddenCondition || []
  );
  const [translation] = useTranslation();

  if (!isLicensed) {
    return null;
  }

  if (!isAuthorised) {
    return null;
  }

  const tenantTagsEnabled = !!Object.keys(tenantSettingsState.tags || {}).length === 0
  if(!tenantTagsEnabled) {
    if(navigationObject.hiddenCondition?.includes('tenant-tags')) {
      return null;
    }
  }

  const screenshotsEnabled = !!companyMetadataState?.tenantFlags?.includes(
    'platform:screenshots'
  );
  if(!screenshotsEnabled) {
    if(navigationObject.hiddenCondition?.includes('screenshots')) {
      return null;
    }
  }

  return (
    <Fragment>
      {navigationObject.link && (
        <Link className={style.navigationLinks} to={navigationObject.link}>
          <ListItem
            className={classnames(style.navigationButton, {
              [style.subMenuItem]: isSubMenu,
            })}
            key={navigationObject.text}
          >
            {!isSubMenu && (
              <ListItemIcon className={style.navigationListIcon}>
                <img
                  className={style.navigationIcon}
                  src={navigationObject.icon}
                />
              </ListItemIcon>
            )}
            <ListItemText
              inset={isSubMenu}
              primaryTypographyProps={{
                className: isSubMenu ? style.subMenuText : null,
              }}
              primary={translation(navigationObject.text)}
              style={{
                display:
                  collapseNavigationList.some((item) => {
                    return item.subMenus.some(
                      (element) => element.text == navigationObject.text
                    );
                  }) &&
                  collapseNavigationList.some(
                    (item) => item.text === parentName
                  )
                    ? 'none'
                    : '',
              }}
            />
          </ListItem>
        </Link>
      )}
      {!navigationObject.link && (
        <ListItem
          className={classnames(
            style.navigationButton,
            style.nonClickableLinks,
            {
              [style.subMenuItem]: isSubMenu,
            }
          )}
          key={navigationObject.text}
          onClick={() => {
            const collapseList = pageNavigationList.find(
              (item) => item.text === navigationObject.text
            );
            const isCollapsed = collapseNavigationList.find(
              (item) => item.text === navigationObject.text
            );

            if (isCollapsed) {
              const expandList = collapseNavigationList.filter(
                (item) => item.text !== navigationObject.text
              );
              setCollapseNavigationList(expandList);
            } else {
              setCollapseNavigationList([
                ...collapseNavigationList,
                collapseList,
              ]);
            }
          }}
        >
          {!isSubMenu && (
            <ListItemIcon className={style.navigationListIcon}>
              <img
                className={style.navigationIcon}
                src={navigationObject.icon}
              />
            </ListItemIcon>
          )}
          <ListItemText
            inset={isSubMenu}
            primaryTypographyProps={{
              className: isSubMenu ? style.subMenuText : null,
            }}
            primary={translation(navigationObject.text)}
            style={{
              display: collapseNavigationList.some((item) => {
                return item.subMenus.includes(navigationObject.text);
              })
                ? 'none'
                : '',
            }}
          />
        </ListItem>
      )}
      {navigationObject.subMenus && !isSubMenu && (
        <List className={style.list}>
          {navigationObject.subMenus.map((subMenuItem) => (
            <NavigationLink
              key={subMenuItem.text}
              navigationObject={subMenuItem}
              isSubMenu
              collapseNavigationList={collapseNavigationList}
              setCollapseNavigationList={setCollapseNavigationList}
              parentName={navigationObject.text}
            />
          ))}
        </List>
      )}
    </Fragment>
  );
};

NavigationLink.defaultProps = {
  isSubMenu: false,
  collapseNavigationList: [],
};

NavigationLink.propTypes = {
  navigationObject: PropTypes.object.isRequired,
  isSubMenu: PropTypes.bool,
  collapseNavigationList: PropTypes.array,
  setCollapseNavigationList: PropTypes.any,
  parentName: PropTypes.any,
};

const NavigationSideBar = () => {
  const style = useStyle();
  const isAuthenticated = useAuthentication();

  const { companyMetadataState } = useCompanyMetadata();
  const [collapseNavigationList, setCollapseNavigationList] = useState([]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Drawer
      variant='permanent'
      className={style.drawer}
      classes={{ paper: style.drawerPaper }}
      data-cy="navigationSideBar"
    >
      <div className={style.toolbar} />
      <List className={style.navigationList}>
        {pageNavigationList.map((navigationObject) => (
          <NavigationLink
            key={navigationObject.text}
            navigationObject={navigationObject}
            collapseNavigationList={collapseNavigationList}
            setCollapseNavigationList={setCollapseNavigationList}
          />
        ))}
      </List>
      <img
        className={style.snobalLogo}
        src={
          companyMetadataState.snobalLogoUrl
            ? companyMetadataState.snobalLogoUrl
            : poweredBySnobalLogo
        }
      />
    </Drawer>
  );
};

export default NavigationSideBar;
