import React from 'react';
import ReactDOM from 'react-dom';
import { Translation } from 'react-i18next';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';

import theme from '../../app/theme';

const ConfirmPageNavigation = (message, callback) => {
  const confirmPageNavigationContainer = document.createElement('div');
  const rootModalElement = document.getElementById('modal-root');
  rootModalElement.appendChild(confirmPageNavigationContainer);

  const closeModal = (callbackState) => {
    ReactDOM.unmountComponentAtNode(confirmPageNavigationContainer);
    callback(callbackState);
  };

  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <Dialog disablePortal open={true}>
        <DialogTitle>
          <Translation>
            {(translate) => translate('confirmPageNavigation.heading')}
          </Translation>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Translation>
              {(translate) => translate('confirmPageNavigation.message')}
            </Translation>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px' }}>
          <Grid container spacing={1} style={{ marginBottom: '24px' }}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                onClick={() => closeModal(false)}
              >
                <Translation>
                  {(translate) => translate('common.cancel')}
                </Translation>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                onClick={() => closeModal(true)}
              >
                <Translation>
                  {(translate) => translate('confirmPageNavigation.leave')}
                </Translation>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </ThemeProvider>,
    confirmPageNavigationContainer
  );
};

export default ConfirmPageNavigation;
