import React from 'react';

import useStyle from './footerStyle';
import useAuthentication from '../../hooks/useAuthentication';

const Footer = () => {
  const style = useStyle();
  const isAuthenticated = useAuthentication();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div>
      <div className={style.footer} />
      <p className={style.footerText}>
        <a
          className={style.footerLinks}
          href='https://snobal.io/submit-support-ticket/'
          target='_blank'
          rel='noreferrer'
        >
          Support
        </a>
        {' | '}
        <a
          className={style.footerLinks}
          href='https://snobal.io/Knowledge/'
          target='_blank'
          rel='noreferrer'
        >
          Knowledge base
        </a>
        {' | '}
        <a
          className={style.footerLinks}
          href='https://snobal.io/terms-use/'
          target='_blank'
          rel='noreferrer'
        >
          Terms of use
        </a>
        {' | '}
        <a
          className={style.footerLinks}
          href='https://snobal.io/privacy-policy/'
          target='_blank'
          rel='noreferrer'
        >
          Privacy policy
        </a>
        {' | '}
        <a
          className={style.footerLinks}
          href='https://snobal.io/contact/'
          target='_blank'
          rel='noreferrer'
        >
          Contact us
        </a>
      </p>
    </div>
  );
};

export default Footer;
