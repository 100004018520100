import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import PrivateRoute from '../components/privateRoute';
import Loader from '../components/loader';

const IndexPage = lazy(() =>
  import(/* webpackChunkName: "indexPage" */ '../pages/indexPage')
);
const LoginPage = lazy(() =>
  import(/* webpackChunkName: "loginPage" */ '../pages/loginPage')
);
const NotAllowAccessPage = lazy(() =>
  import(
    /* webpackChunkName: "notAllowAccessPage" */ '../pages/notAllowAccessPage'
  )
);
const ForgotPasswordPage = lazy(() =>
  import(
    /* webpackChunkName: "forgotPasswordPage" */ '../pages/forgotPasswordPage'
  )
);
const ResetPasswordPage = lazy(() =>
  import(
    /* webpackChunkName: "resetPasswordPage" */ '../pages/resetPasswordPage'
  )
);
const LogoutPage = lazy(() =>
  import(/* webpackChunkName: "logoutPage" */ '../pages/logoutPage')
);
const HomePage = lazy(() =>
  import(/* webpackChunkName: "homepage" */ '../pages/homePage')
);
const DashboardPage = lazy(() =>
  import(/* webpackChunkName: "dashboardPage" */ '../pages/dashboardPage')
);
const PresentationsPage = lazy(() =>
  import(
    /* webpackChunkName: "presentationsPage" */ '../pages/presentationsPage'
  )
);
const NewPresentationsPage = lazy(() =>
  import(
    /* webpackChunkName: "newPresentationsPage" */ '../pages/newPresentationsPage'
  )
);
const SessionsPage = lazy(() =>
  import(/* webpackChunkName: "sessionsPage" */ '../pages/sessionsPage')
);
const ReportsPage = lazy(() =>
  import(/* webpackChunkName: "reportsPage" */ '../pages/reportsPage')
);
const DevicesPage = lazy(() =>
  import(/* webpackChunkName: "devicesPage" */ '../pages/devicesPage')
);
const UsersPage = lazy(() =>
  import(/* webpackChunkName: "usersPage" */ '../pages/usersPage')
);
const AttendeesPage = lazy(() =>
  import(/* webpackChunkName: "attendeesPage" */ '../pages/attendeesPage')
);
const UserProfilePage = lazy(() =>
  import(/* webpackChunkName: "userProfilePage" */ '../pages/userProfilePage')
);
const StatusPage = lazy(() =>
  import(/* webpackChunkName: "statusPage" */ '../pages/statusPage')
);
const MediaPage = lazy(() =>
  import(/* webpackChunkName: "mediaPage" */ '../pages/mediaPage')
);
const ScreenshotPage = lazy(() =>
  import(/* webpackChunkName: "ScreenshotPage" */ '../pages/screenshotPage')
);
const DocumentsPage = lazy(() =>
  import(/* webpackChunkName: "documentsPage" */ '../pages/documentsPage')
);
const FacilitiesPage = lazy(() =>
  import(/* webpackChunkName: "facilitiesPage" */ '../pages/facilitiesPage')
);
const ZonesPage = lazy(() =>
  import(/* webpackChunkName: "zonesPage" */ '../pages/zonesPage')
);
const AssetsPage = lazy(() =>
  import(/* webpackChunkName: "assetsPage" */ '../pages/assetsPage')
);
const ApplicationsPage = lazy(() =>
  import(/* webpackChunkName: "applicationsPage" */ '../pages/applicationsPage')
);
const appReportsPage = lazy(() =>
  import(/* webpackChunkName: "appReportsPage" */ '../pages/appReportsPage')
);
const DeviceProfilesPage = lazy(() =>
  import(
    /* webpackChunkName: "deviceProfilesPage" */ '../pages/deviceProfilesPage'
  )
);
const SettingsPage = lazy(() =>
  import(/* webpackChunkName: "settingsPage" */ '../pages/settingsPage')
);
const SupportPage = lazy(() =>
  import(/* webpackChunkName: "supportPage" */ '../pages/supportPage')
);
const AppRegistryPage = lazy(() =>
  import(/* webpackChunkName: "appRegistryPage" */ '../pages/appRegistryPage')
);
const SpherePresentationPage = lazy(() =>
  import(
    /* webpackChunkName: "spherePresentationPage" */ '../pages/spherePresentationPage'
  )
);
const NewSpherePresentationsPage = lazy(() =>
  import(
    /* webpackChunkName: "newSpherePresentationsPage" */ '../pages/newSpherePresentationsPage'
  )
);
const RoomsPage = lazy(() =>
  import(/* webpackChunkName: "roomsPage" */ '../pages/roomsPage')
);

const DiscoverPage = lazy(() =>
  import(/* webpackChunkName: "discoverPage" */ '../pages/discoverPage')
);

const JoinAMeetingPage = lazy(() =>
  import(/* webpackChunkName: "joinAMeetingPage" */ '../pages/joinAMeetingPage')
);

const ConfigurationPage = lazy(() =>
  import(
    /* webpackChunkName: "configurationPage" */ '../pages/configurationPage'
  )
);

const createRedirect = (to) => {
  const CreateRedirect = () => <Redirect to={to} />;
  return CreateRedirect;
};

const Routes = () => {
  return (
    <Suspense fallback={<Loader pageLoader />}>
      <Switch>
        {/* PUBLIC PATHS */}
        <Route exact path='/app' component={IndexPage} />
        <Route exact path='/app/index' component={IndexPage} />
        <Route exact path='/app/login' component={LoginPage} />
        <Route
          exact
          path='/app/notAllowAccess'
          component={NotAllowAccessPage}
        />
        <Route
          exact
          path='/app/forgotPassword'
          component={ForgotPasswordPage}
        />
        <Route exact path='/app/resetPassword' component={ResetPasswordPage} />
        <Route exact path='/app/logout' component={LogoutPage} />

        {/* PRIVATE PATHS */}
        <PrivateRoute
          exact
          path='/app/settings/configurations'
          component={ConfigurationPage}
        ></PrivateRoute>

        <PrivateRoute
          exact
          path='/app/rooms'
          component={RoomsPage}
          permissions={['any']}
        />
        <PrivateRoute
          exact
          path='/app/discover'
          component={DiscoverPage}
          permissions={['any']}
        />
        <PrivateRoute
          exact
          path='/app/joinameeting'
          component={JoinAMeetingPage}
          permissions={['any']}
        />
        <PrivateRoute
          exact
          path='/app/home'
          component={HomePage}
          permissions={['any']}
        />
        <PrivateRoute
          exact
          path='/app/dashboard'
          permissions={['view-reports']}
          component={DashboardPage}
        />
        <PrivateRoute
          exact
          path='/app/presentations/'
          permissions={['view-presentations', 'edit-presentations']}
          component={PresentationsPage}
        />
        <PrivateRoute
          exact
          path='/app/presentations/createPresentation'
          permissions={['edit-presentations']}
          component={NewPresentationsPage}
        />
        <PrivateRoute
          exact
          path='/app/presentations/:presentationId'
          permissions={['edit-presentations', 'translate-experiences']}
          component={NewPresentationsPage}
        />
        <PrivateRoute
          exact
          path='/app/experiences/'
          permissions={['edit-presentations']}
          component={PresentationsPage}
        />
        <PrivateRoute
          exact
          path='/app/experiences/:experienceId'
          permissions={['edit-presentations']}
          component={PresentationsPage}
        />
        <PrivateRoute
          exact
          path='/app/sessions'
          permissions={['view-sessions', 'edit-sessions']}
          component={SessionsPage}
        />
        <PrivateRoute
          exact
          path='/app/sessions/createSession'
          permissions={['edit-sessions']}
          component={SessionsPage}
        />
        <PrivateRoute
          exact
          path='/app/sessions/:sessionId'
          permissions={['edit-sessions']}
          component={SessionsPage}
        />
        <PrivateRoute
          exact
          path='/app/reports'
          permissions={['view-reports']}
          component={ReportsPage}
        />
        <PrivateRoute
          exact
          path='/app/reports/activities'
          permissions={['view-reports']}
          component={ReportsPage}
        />
        <PrivateRoute
          exact
          path='/app/reports/tasks'
          permissions={['view-reports']}
          component={ReportsPage}
        />
        <PrivateRoute
          exact
          path='/app/reports/tasks/:activityId'
          permissions={['view-reports']}
          component={ReportsPage}
        />
        <PrivateRoute
          exact
          path='/app/devices'
          permissions={['view-devices', 'manage-devices']}
          component={DevicesPage}
        />
        <PrivateRoute
          exact
          path='/app/devices/addNew'
          permissions={['manage-devices']}
          component={DevicesPage}
        />
        <PrivateRoute
          exact
          path='/app/devices/:deviceId'
          permissions={['manage-devices']}
          component={DevicesPage}
        />
        <PrivateRoute
          exact
          path='/app/devices/:deviceId/log'
          permissions={['manage-devices']}
          component={DevicesPage}
        />
        <PrivateRoute
          exact
          path='/app/users'
          permissions={['view-users', 'manage-users']}
          component={UsersPage}
        />
        <PrivateRoute
          exact
          path='/app/users/addNew'
          permissions={['manage-users']}
          component={UsersPage}
        />
        <PrivateRoute
          exact
          path='/app/users/:userId'
          permissions={['manage-users']}
          component={UsersPage}
        />
        <PrivateRoute
          exact
          path='/app/attendees'
          permissions={['fetch-attendee-list', 'edit-sessions']}
          component={AttendeesPage}
        />
        <PrivateRoute
          exact
          path='/app/attendees/addNew'
          permissions={['edit-sessions']}
          component={AttendeesPage}
        />
        <PrivateRoute
          exact
          path='/app/attendees/:attendeeId'
          permissions={['edit-sessions']}
          component={AttendeesPage}
        />
        <PrivateRoute
          exact
          path='/app/profile'
          component={UserProfilePage}
          permissions={['any']}
        />
        <PrivateRoute
          exact
          path='/app/media'
          permissions={['view-media', 'manage-media']}
          component={MediaPage}
        />
        <PrivateRoute
          exact
          path='/app/media/addNew'
          permissions={['manage-media']}
          component={MediaPage}
        />
        <PrivateRoute
          exact
          path='/app/media/:mediaId'
          permissions={['manage-media']}
          component={MediaPage}
        />
        <PrivateRoute
          exact
          path='/app/screenshots'
          permissions={['manage-media']}
          component={ScreenshotPage}
        />
        <PrivateRoute
          exact
          path='/app/screenshots/:mediaId'
          permissions={['manage-media']}
          component={ScreenshotPage}
        />
        <PrivateRoute
          exact
          path='/app/facilities'
          permissions={['view-facilities', 'manage-facilities']}
          component={FacilitiesPage}
        />
        <PrivateRoute
          exact
          path='/app/facilities/addNew'
          permissions={['manage-facilities']}
          component={FacilitiesPage}
        />
        <PrivateRoute
          exact
          path='/app/facilities/:facilityId'
          permissions={['view-facilities', 'manage-facilities']}
          component={FacilitiesPage}
        />
        <PrivateRoute
          exact
          path='/app/zones'
          permissions={['view-facilities', 'manage-facilities']}
          component={ZonesPage}
        />
        <PrivateRoute
          exact
          path='/app/zones/addNew'
          permissions={['manage-facilities']}
          component={ZonesPage}
        />
        <PrivateRoute
          exact
          path='/app/zones/:zoneId'
          permissions={['view-facilities', 'manage-facilities']}
          component={ZonesPage}
        />
        <PrivateRoute
          exact
          path='/app/assets'
          permissions={['view-facilities', 'manage-facilities']}
          component={AssetsPage}
        />
        <PrivateRoute
          exact
          path='/app/assets/addNew'
          permissions={['manage-facilities']}
          component={AssetsPage}
        />
        <PrivateRoute
          exact
          path='/app/assets/:assetId'
          permissions={['view-facilities', 'manage-facilities']}
          component={AssetsPage}
        />
        <PrivateRoute
          exact
          path='/app/documents'
          permissions={['view-facilities', 'manage-facilities']}
          component={DocumentsPage}
        />
        <PrivateRoute
          exact
          path='/app/documents/addNew'
          permissions={['manage-facilities']}
          component={DocumentsPage}
        />
        <PrivateRoute
          exact
          path='/app/documents/:documentId'
          permissions={['manage-facilities']}
          component={DocumentsPage}
        />
        <PrivateRoute
          exact
          path='/app/applications'
          permissions={['view-devices', 'manage-devices']}
          component={ApplicationsPage}
        />
        <PrivateRoute
          exact
          path='/app/applications/addNew'
          permissions={['manage-devices']}
          component={ApplicationsPage}
        />
        <PrivateRoute
          exact
          path='/app/applications/:applicationId'
          permissions={['manage-devices']}
          component={ApplicationsPage}
        />
        <PrivateRoute
          exact
          path='/app/applicationReports'
          permissions={['any']}
          component={appReportsPage}
        />
        <PrivateRoute
          exact
          path='/app/applicationReports/:applicationId'
          permissions={['any']}
          component={appReportsPage}
        />
        <PrivateRoute
          exact
          path='/app/deviceProfiles'
          permissions={['view-devices', 'manage-devices']}
          component={DeviceProfilesPage}
        />
        <PrivateRoute
          exact
          path='/app/deviceProfiles/addNew'
          permissions={['manage-devices']}
          component={DeviceProfilesPage}
        />
        <PrivateRoute
          exact
          path='/app/deviceProfiles/:deviceProfileId'
          permissions={['manage-devices']}
          component={DeviceProfilesPage}
        />
        <PrivateRoute
          exact
          path='/app/settings'
          permissions={['view-tenant', 'manage-tenant']}
          component={SettingsPage}
        />
        <PrivateRoute
          exact
          path='/app/support'
          permissions={['any']}
          component={SupportPage}
        />
        <PrivateRoute
          exact
          path='/app/appRegistry'
          permissions={['any']}
          component={AppRegistryPage}
        />
        <PrivateRoute
          exact
          path='/app/appRegistry/:applicationId'
          permissions={['any']}
          component={AppRegistryPage}
        />
        <PrivateRoute
          exact
          path='/app/appRegistry/addNewScheme/:applicationId'
          permissions={['any']}
          component={AppRegistryPage}
        />
        <PrivateRoute
          exact
          path='/app/appRegistry/addNewExecutable'
          permissions={['any']}
          component={AppRegistryPage}
        />
        <PrivateRoute
          exact
          path='/app/appRegistry/addNewExecutable/:applicationId'
          permissions={['any']}
          component={AppRegistryPage}
        />
        <PrivateRoute
          exact
          path='/app/appRegistry/tasks/:appRegistrySchemeId'
          permissions={['any']}
          component={AppRegistryPage}
        />
        <PrivateRoute
          exact
          path='/app/sphere-presentations'
          permissions={['any']}
          component={SpherePresentationPage}
        />
        <PrivateRoute
          exact
          path='/app/sphere-presentations/createSpherePresentation'
          permissions={['any']}
          component={NewSpherePresentationsPage}
        />
        <PrivateRoute
          exact
          path='/app/sphere-presentations/:presentationId'
          permissions={['any']}
          component={NewSpherePresentationsPage}
        />
        <Route exact path='/app/status/:status' component={StatusPage} />

        {/* PAGE NOT FOUND */}
        <Route component={createRedirect('/app/status/notfound')} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
