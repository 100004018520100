import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import useStyle from './navigationTopBarStyle';
import useAuthentication from '../../hooks/useAuthentication';
import useLoggedInUser from '../../hooks/useLoggedInUser';
import useCompanyMetadata from '../../hooks/useCompanyMetadata';

import dropdownArrowIcon from '../../assets/icons/dropdown-arrow.svg';

const NavigationTopBar = () => {
  const style = useStyle();
  const isAuthenticated = useAuthentication();
  const { loggedInUserState } = useLoggedInUser();
  const { companyMetadataState } = useCompanyMetadata();
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);
  const [translation] = useTranslation();

  const handleUserMenuOpen = (event) => {
    setUserMenuAnchor(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setUserMenuAnchor(null);
  };

  const renderUserMenu = (
    <Menu
      id='userDetailsMenu'
      anchorEl={userMenuAnchor}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={Boolean(userMenuAnchor)}
      keepMounted
      onClose={handleUserMenuClose}
    >
      <Link className={style.navigationLink} to={'/app/profile'}>
        <MenuItem
          className={style.navigationLinkText}
          onClick={handleUserMenuClose}
          data-cy="navigation.profile"
        >
          {translation('navigation.profile')}
        </MenuItem>
      </Link>
      <Divider />
      <Link className={style.navigationLink} to={'/app/logout'}>
        <MenuItem
          className={style.navigationLinkText}
          onClick={handleUserMenuClose}
        >
          {translation('navigation.logout')}
        </MenuItem>
      </Link>
    </Menu>
  );

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Fragment>
      <AppBar position='fixed' className={style.topBar}>
        <Toolbar>
          <Link className={style.logoLink} to='/app/index'>
            <img className={style.logo} src={companyMetadataState.logoUrl} />
          </Link>
          <div className={style.grow} />
          <Button onClick={handleUserMenuOpen}>
            {loggedInUserState.name}&nbsp;
            <img className={style.icon} src={dropdownArrowIcon} />
          </Button>
        </Toolbar>
      </AppBar>
      {renderUserMenu}
    </Fragment>
  );
};

export default NavigationTopBar;
