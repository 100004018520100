import moment from 'moment';
import { getServerUrl } from './api';
import imageIcon from '../assets/icons/no-image.svg'
import audioIcon from '../assets/icons/audio.svg';
import videoIcon from '../assets/icons/video.svg';
import documentIcon from '../assets/icons/documents.svg';
import modelIcon from '../assets/icons/3d-model.svg';

export const webRtcStates = {
  NOT_STARTED: 'NOT_STARTED',
  CONFIGURED: 'CONFIGURED',
  STARTING_CALL: 'STARTING_CALL',
  LISTENING: 'LISTENING',
  IN_CALL: 'IN_CALL',
  CALL_CLOSED: 'CALL_CLOSED',
  CALL_DISCONNECTED: 'CALL_DISCONNECTED',
  CONNECTION_ERROR: 'CONNECTION_ERROR',
  LISTENING_ERROR: 'LISTENING_ERROR',
};

export const deviceStreamStates = {
  UNAVAILABLE: 'UNAVAILABLE',
  IN_USE: 'IN_USE',
  AVAILABLE: 'AVAILABLE',
};

export const defaultDateFilter = {
  to: moment().endOf('day'),
  from: moment().subtract(1, 'month').startOf('day'),
};

export const isUuid = (uuid: string) => {
  if (!uuid) return false;

  const uuidRegEx = new RegExp(
    /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
  );
  return uuid.match(uuidRegEx) !== null;
};

export const dynamicSort = (property: string, metadata: any[] = null) => {
  var sortOrder = 1;
  if (property && property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  const propertyMetadata = metadata
    ? metadata.filter((data) => data.property === property)[0] || null
    : null;

  return (a: any, b: any) => {
    let variableA = null;
    let variableB = null;

    if (propertyMetadata && propertyMetadata.convertToString) {
      variableA = propertyMetadata.convertToString(a);
      variableB = propertyMetadata.convertToString(b);
    } else if (
      !Object.prototype.hasOwnProperty.call(a, property) ||
      !Object.prototype.hasOwnProperty.call(b, property)
    ) {
      return 0;
    } else {
      variableA =
        typeof a[property] === 'string'
          ? a[property].toUpperCase()
          : a[property];
      variableB =
        typeof b[property] === 'string'
          ? b[property].toUpperCase()
          : b[property];
    }

    if (!variableA) {
      return 1 * sortOrder;
    }
    if (!variableB) {
      return -1 * sortOrder;
    }

    let comparison = 0;
    if (
      typeof variableA === 'number' &&
      typeof variableA === typeof variableB
    ) {
      if (variableA > variableB) {
        comparison = -1;
      } else if (variableA < variableB) {
        comparison = 1;
      }
    } else {
      if (variableA > variableB) {
        comparison = 1;
      } else if (variableA < variableB) {
        comparison = -1;
      }
    }

    return comparison * sortOrder;
  };
};

export const dynamicStringSort = (property: string) => {
  var sortOrder = 1;

  if (property && property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return (a: any, b: any) => {
    if (
      Object.prototype.hasOwnProperty.call(a, property) &&
      Object.prototype.hasOwnProperty.call(b, property)
    ) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
    return 0;
  };
};

export const searchList = (
  searchQuery: string,
  list: any[],
  rowMetadata: any[],
  setResults: Function
) => {
  const searchableColumns = rowMetadata.filter((column) => column.searchable);

  if (searchQuery && list && list.length > 0) {
    setResults(
      list.filter((item) =>
        searchableColumns.some((column) => {
          if (column.convertToString) {
            return column
              .convertToString(item)
              .toUpperCase()
              .includes(searchQuery.toUpperCase());
          }
          if (item[column.property]) {
            return item[column.property]
              .toUpperCase()
              .includes(searchQuery.toUpperCase());
          }
          return false;
        })
      )
    );
  } else {
    setResults(list);
  }
};

export const hasScrollbar = (element: Element) => {
  return (
    element &&
    (element.clientWidth < element.scrollWidth ||
      element.clientHeight < element.scrollHeight)
  );
};

export const getScrollbarDimensions = () => {
  const { body } = document;
  const scrollDiv: any = document.createElement('div');

  // Append element with defined styling
  scrollDiv.setAttribute(
    'style',
    'width: 1337px; height: 1337px; position: absolute; left: -9999px; overflow: scroll;'
  );
  body.appendChild(scrollDiv);

  // Collect the width and height of the scrollbar
  const calculateValue = (type: string) =>
    scrollDiv[`offset${type}`] - scrollDiv[`client${type}`];
  const scrollbarWidth = calculateValue('Width');
  const scrollbarHeight = calculateValue('Height');

  // Remove element
  body.removeChild(scrollDiv);

  return {
    width: scrollbarWidth,
    height: scrollbarHeight,
  };
};

export const downloadAsTextFile = (filename: string, text: string) => {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const downloadAsCsvFile = (
  filename: string,
  rows: Record<string, any>[]
) => {
  const getHeaders = (row: Record<string, any>) => {
    return Object.keys(row);
  };
  const processObjectRow = function (
    headers: string[],
    row: Record<string, any>
  ) {
    var finalVal = '';
    for (var j = 0; j < headers.length; j++) {
      const value = row[headers[j]];
      let innerValue =  value?.toString() || '';
      if (value instanceof Date) {
        innerValue = value.toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };
  const headers = getHeaders(rows[0]);
  const headersRow = headers.join(',') + '\n';
  var csvFile = headersRow;
  for (var i = 0; i < rows.length; i++) {
    csvFile += processObjectRow(headers, rows[i]);
  }
  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  //@ts-ignore
  if (navigator.msSaveBlob) {
    // IE 10+
    //@ts-ignore
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};


export const copyToClipboard = (text: string, html: string) => {
  const copyListener = (event: ClipboardEvent) => {
    event.clipboardData.setData('text/plain', text);
    event.clipboardData.setData('text/html', html);
    event.preventDefault();
  };

  const element = document.createElement('textarea');
  element.value = html;
  element.setAttribute('readonly', 'true');
  element.setAttribute('contenteditable', 'true');
  element.style.position = 'absolute';
  element.style.left = '-9999px';

  document.body.appendChild(element);
  element.select();
  document.addEventListener('copy', copyListener);
  document.execCommand('copy');
  document.removeEventListener('copy', copyListener);
  document.body.removeChild(element);
};

export const copyToClipboardWithStyle = (text: string, html: string) => {
  const copyListener = (event: ClipboardEvent) => {
    event.clipboardData.setData('text/plain', text);
    event.clipboardData.setData('text/html', html);
    event.preventDefault();
  };
  document.addEventListener('copy', copyListener);
  document.execCommand('copy');
  document.removeEventListener('copy', copyListener);
};

export const saveFile = (data: BlobPart, filename: string) => {
  const blob = new Blob([data]);
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getAssetType = (mimeType: string) => {
  if (mimeType) {
    if (mimeType.includes('image')) {
      return 'Image';
    } else if (mimeType.includes('video')) {
      return 'Video';
    } else if (mimeType.includes('audio')) {
      return 'Audio';
    } else if (mimeType.includes('pdf')) {
      return 'PDF';
    } else if (mimeType.includes('model')) {
      return 'Model';
    }
  }
  return '';
};

export const getAssetTypeIcon = (type: string) => {
  if (type) {
    if (type.includes('image')) {
      return imageIcon;
    } else if (type.includes('video')) {
      return videoIcon;
    } else if (type.includes('audio')) {
      return audioIcon;
    } else if (type.includes('pdf')) {
      return documentIcon;
    } else if (type.includes('model')) {
      return modelIcon;
    }
  }
  return imageIcon;
};

export const isValidAssetFormat = (mimeType: string) => {
  const isValid3DModel = mimeType === 'model/gltf-binary';
  const isValidImage = mimeType === 'image/png' || mimeType === 'image/jpeg';
  const isValidVideo = mimeType === 'video/mp4';
  const isValidAudio =
    mimeType === 'audio/wave' ||
    mimeType === 'audio/wav' ||
    mimeType === 'audio/x-wav' ||
    mimeType === 'audio/x-pn-wav';
  return isValidImage || isValidVideo || isValidAudio || isValid3DModel;
};

export const getAssetThumbnailUrl = (
  assetId: string,
  variant: string,
  namespace: string,
  modifyTime: string
) => {
  return getServerUrl(
    `/dam/v2/derivative/${namespace}/${assetId}/${variant}/thumbnail?_v=${modifyTime}`
  );
};

export const getAssetUrl = (
  assetId: string,
  variant: string,
  namespace: string
) => {
  if (variant) {
    return getServerUrl(`/dam/v2/asset/${namespace}/${assetId}/${variant}`);
  } else {
    return getServerUrl(`/dam/v2/asset/${namespace}/${assetId}`);
  }
};

export const getBase64 = async (file: File) => {
  return new Promise((res, rej) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      res(reader.result);
    };
    reader.onerror = function (error) {
      rej(error);
    };
  });
};

export const uniqueFilter = (value: any, index: any, self: any) => {
  return self.indexOf(value) === index;
};

export const isImage = (asset: { primary: { mimeType: string } }) =>
  asset &&
  asset.primary &&
  asset.primary.mimeType &&
  asset.primary.mimeType.includes('image');

export const isVideo = (asset: { primary: { mimeType: string } }) =>
  asset &&
  asset.primary &&
  asset.primary.mimeType &&
  asset.primary.mimeType.includes('video');

export const isModel = (asset: { primary: { mimeType: string } }) =>
  asset &&
  asset.primary &&
  asset.primary.mimeType &&
  asset.primary.mimeType.includes('model');

export const isAudio = (asset: { primary: { mimeType: string } }) =>
  asset &&
  asset.primary &&
  asset.primary.mimeType &&
  asset.primary.mimeType.includes('audio');

export const percentageDifference = (a: number, b: number): number | null => {
  if (a === b) {
    return null;
  }
  if (b === 0) {
    return null;
  }

  if (a > b) {
    return Math.floor((Math.abs(a - b) / b) * 100);
  } else {
    return Math.floor((Math.abs(b - a) / b) * -100);
  }
};

const compare = (a: Object, b: Object): Boolean => {
  const type = Object.prototype.toString.call(a);

  // if object or array, compare recursively
  if (['[object Array]', '[object Object]'].indexOf(type) >= 0) {
    if (!isEqual(a, b)) return false;
  }
  // otherwise do a simple comparison
  else {
    // if the two items are not the same type, return false
    if (type !== Object.prototype.toString.call(b)) return false;

    // if it's a function, convert to a string and compare
    if (type === '[object Function]') {
      if (a.toString() !== b.toString()) return false;
    } else {
      if (a !== b) return false;
    }
  }

  return true;
};

export const isEqual = (a: any, b: any): Boolean => {
  const type = Object.prototype.toString.call(a);

  // if the two item are not the same type, return false
  if (type !== Object.prototype.toString.call(b)) return false;

  // if items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // compare the length of the two items
  const lengthA = type === '[object Array]' ? a.length : Object.keys(a).length;
  const lengthB = type === '[object Array]' ? b.length : Object.keys(b).length;
  if (lengthA !== lengthB) return false;

  // compare properties
  if (type === '[object Array]') {
    for (let i = 0; i < lengthA; i++) {
      if (compare(a[i], b[i]) === false) return false;
    }
  } else {
    for (let key in a) {
      if (a.hasOwnProperty(key)) {
        if (compare(a[key], b[key]) === false) return false;
      }
    }
  }

  // if nothing failed return true
  return true;
};
