import { useEffect, useRef, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

import useRouter from './useRouter';
import { getServerUrl } from '../utility/api';

interface StateObject<T> {
  loading: boolean;
  data: T;
  error: boolean;
  wasSuccessful: boolean;
  called: boolean;
  response: any;
}

const initialState = {
  loading: false,
  called: false,
  data: null as any,
  error: false,
  wasSuccessful: false,
  response: null as any,
};

const useAxios: <T = any>(
  axiosConfig: AxiosRequestConfig,
  immediate?: boolean
) => readonly [
  StateObject<T>,
  (configOverride?: AxiosRequestConfig) => Promise<void>
] = (axiosConfig: AxiosRequestConfig, immediate = true) => {
  const router = useRouter();
  const isMounted = useRef(true);

  const [state, setState] = useState({ ...initialState, loading: immediate });
  const [source] = useState(axios.CancelToken.source());

  const request = async (config: AxiosRequestConfig) => {
    setState({ ...initialState, loading: true, called: true });

    await axios(config)
      .then((response) => {
        if (isMounted.current) {
          setState({
            ...state,
            loading: false,
            data: response.data,
            error: false,
            wasSuccessful: true,
            called: true,
            response,
          });
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          const onLoginPage = router.pathname.includes('login');
          const onResetPasswordPage = router.pathname.includes('resetPassword');
          if (
            !onLoginPage &&
            !onResetPasswordPage &&
            error.response &&
            error.response.status === 403
          ) {
            router.replace('/app/logout');
          }

          setState({
            ...state,
            loading: false,
            called: true,
            data: error.response?.data,
            error: true,
            wasSuccessful: false,
            response: error.response,
          });
        }
      });
  };

  if (typeof axiosConfig === 'string') {
    axiosConfig = {
      url: axiosConfig,
    };
  }

  axiosConfig.baseURL = getServerUrl();
  axiosConfig.withCredentials = true;
  axiosConfig.cancelToken = source.token;

  if (!axiosConfig.timeout) {
    axiosConfig.timeout = 10000;
  }

  useEffect(() => {
    if (immediate) {
      request(axiosConfig);
    }
    return () => {
      if (isMounted.current) {
        isMounted.current = false;
        source.cancel();
      }
    };
  }, []);

  return [
    state,
    async (configOverride?: AxiosRequestConfig) => {
      let replaceCredentialsExisted = Object.getOwnPropertyDescriptor(
        axiosConfig,
        'replaceCredentials'
      );
      if (
        replaceCredentialsExisted &&
        replaceCredentialsExisted.value === true
      ) {
        axiosConfig.withCredentials = false;
      }
      await request({ ...axiosConfig, ...configOverride });
    },
  ] as const;
};

export default useAxios;
