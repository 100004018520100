import { createContext } from 'react';

export type TGlobalStoreState = {
  focusedSessionId?: string;
  filterNamespace: string;
  pageNumber?: number;
  searchQuery: any;
  sortingProperty: any;
  filter: any;
  experiencePageSelectedTab: number;
  tenantLocations: any[];
  enableAuth0: boolean;
};

export const globalStoreInitialState: TGlobalStoreState = {
  focusedSessionId: null,
  filterNamespace: '',
  pageNumber: null,
  searchQuery: null,
  sortingProperty: null,
  filter: {},
  experiencePageSelectedTab: 0,
  tenantLocations: [],
  enableAuth0: false,
};

export const globalStoreActions = {
  SET_FOCUSED_SESSION: 'SET_FOCUSED_SESSION',
  SET_FILTER_NAMESPACE: 'SET_FILTER_NAMESPACE',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  SET_SORTING_PROPERTY: 'SET_SORTING_PROPERTY',
  SET_FILTER: 'SET_FILTER',
  SET_SELECTED_TAB_EXPERIENCE_PAGE: 'SET_SELECTED_TAB_EXPERIENCE_PAGE',
  SET_TENANT_LOCATIONS: 'SET_TENANT_LOCATIONS',
  SET_AUTH_TYPE: 'SET_AUTH_TYPE',
};

export const globalAuthType = {
  LOCAL: 'local',
  AUTH0: 'auth0',
};

export const globalStoreReducer = (
  state = globalStoreInitialState,
  action: any
) => {
  switch (action.type) {
    case globalStoreActions.SET_FOCUSED_SESSION:
      return {
        ...state,
        focusedSessionId: action.focusedSessionId,
      };

    case globalStoreActions.SET_FILTER_NAMESPACE:
      if (state.filterNamespace !== action.filterNamespace) {
        if (action.filterNamespace.includes('mediaPage')) {
          return {
            ...state,
            filterNamespace: action.filterNamespace,
            pageNumber: globalStoreInitialState.pageNumber,
            sortingProperty: globalStoreInitialState.sortingProperty,
            filter: {},
          };
        } else {
          return {
            ...state,
            filterNamespace: action.filterNamespace,
            pageNumber: globalStoreInitialState.pageNumber,
            searchQuery: globalStoreInitialState.searchQuery,
            sortingProperty: globalStoreInitialState.sortingProperty,
            filter: {},
          };
        }
      } else {
        return {
          ...state,
        };
      }
    case globalStoreActions.SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.pageNumber,
      };
    case globalStoreActions.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.searchQuery,
      };
    case globalStoreActions.SET_SORTING_PROPERTY:
      return {
        ...state,
        sortingProperty: action.sortingProperty,
      };
    case globalStoreActions.SET_FILTER:
      return {
        ...state,
        filter: action.filters,
      };

    case globalStoreActions.SET_SELECTED_TAB_EXPERIENCE_PAGE:
      return {
        ...state,
        experiencePageSelectedTab: action.selectedTab,
      };

    case globalStoreActions.SET_TENANT_LOCATIONS:
      return {
        ...state,
        tenantLocations: action.tenantLocations,
      };

    case globalStoreActions.SET_AUTH_TYPE:
      return {
        ...state,
        enableAuth0: action.authType == globalAuthType.AUTH0,
      };

    default:
      return { ...state };
  }
};

export const GlobalStoreContext = createContext({
  state: globalStoreInitialState,
  dispatch: null,
});
