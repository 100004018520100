import React, { useMemo } from 'react';
import create from 'zustand';
import { v4 as uuid } from 'uuid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert, { Color } from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

type TNotification = {
  id: string;
  open?: boolean;
  timeout?: number;
  type?: Color;
  message: string;
  anchorOrigin?: any;
  createdAt: number;
};

interface NotificationState {
  notifications: TNotification[];
  add: (data: Omit<TNotification, 'id' | 'createdAt'>) => void;
  remove: (id: string) => void;
  clear: () => void;
}

export const useNotificationStore = create<NotificationState>((set) => ({
  notifications: [],
  add: (data) =>
    set((state) => ({
      notifications: [
        ...state.notifications,
        { id: uuid(), createdAt: Date.now(), ...data },
      ],
    })),
  remove: (id) =>
    set((state) => ({
      notifications: [...state.notifications].filter((n) => n.id !== id),
    })),
  clear: () => set(() => ({ notifications: [] })),
}));

export const Notifications: React.FC = () => {
  const notifications = useNotificationStore((state) =>
    state.notifications.filter(
      (notification) => Date.now() <= notification.createdAt + 3000
    )
  );
  const remove = useNotificationStore((state) => state.remove);
  const component = useMemo(
    () =>
      notifications.map((notification) => (
        <Snackbar
          TransitionProps={{
            appear: false,
          }}
          key={notification.id}
          open={true}
          anchorOrigin={
            notification.anchorOrigin || {
              vertical: 'bottom',
              horizontal: 'center',
            }
          }
          autoHideDuration={notification.timeout || 3000}
          onClose={() => {
            remove(notification.id);
          }}
        >
          <Alert variant='filled' severity={notification.type}>
            <AlertTitle>{notification.type.toUpperCase()}</AlertTitle>
            {notification.message}
          </Alert>
        </Snackbar>
      )),
    [notifications]
  );
  return <>{component}</>;
};
