import React from 'react';
import PropTypes from 'prop-types';

import LoadingIndicator from './loadingIndicator';
import useAuthentication from '../../hooks/useAuthentication';

const Loader = ({ delay, pageLoader }) => {
  const isAuthenticated = useAuthentication();

  const DelayedLoadingIndicator = () => <LoadingIndicator delay={delay} />;

  if (pageLoader) {
    if (isAuthenticated) {
      return (
        <div style={{ width: '100%', height: '100vh' }}>
          <DelayedLoadingIndicator />
        </div>
      );
    } else {
      // show no loader with unauthenticated/public page
      return null;
    }
  }

  return <DelayedLoadingIndicator />;
};

Loader.defaultProps = {
  delay: 250,
  pageLoader: false,
};

Loader.propTypes = {
  delay: PropTypes.number,
  pageLoader: PropTypes.bool,
};

export default Loader;
