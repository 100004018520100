import React, { useEffect, useContext, useState } from 'react';
import useAxios from '../../hooks/useAxios';
import {
  CompanyMetadataContext,
  companyMetadataActions,
} from '../../contexts/companyMetadata';
import useRouter from '../../hooks/useRouter';
import useTenantSettings from '../../hooks/useTenantSettings';
import { defaultEnvironments } from '../../utility/configurations';

const ConfigurationLoader: React.FC = () => {
  const router = useRouter();
  const [pathname, setPathname] = useState(router.pathname.valueOf());

  useEffect(() => {
    if (router.pathname.valueOf() !== pathname.valueOf()) {
      setPathname(router.pathname.valueOf());
    }
  }, [router.pathname]);

  const {
    tenantSettingsState,
    tenantSettingsDispatch,
    tenantSettingsActions,
  } = useTenantSettings();

  const companyMetadataDispatch = useContext(CompanyMetadataContext);
  const [companyMetadataState, companyMetadataRequest] = useAxios(
    {
      url: '/api/company/v1/metadata',
      method: 'GET',
    },
    false
  );
  useEffect(() => {
    if (companyMetadataState.wasSuccessful) {
      companyMetadataDispatch.dispatch({
        type: companyMetadataActions.SET_COMPANY_METADATA,
        metadata: companyMetadataState.data,
      });
    }
  }, [companyMetadataState]);

  const [getLanguagesState, getLanguagesRequest] = useAxios(
    {
      url: '/web/api/tenant/v1/get-languages',
      method: 'GET',
    },
    false
  );
  useEffect(() => {
    if (getLanguagesState.wasSuccessful) {
      tenantSettingsDispatch({
        type: tenantSettingsActions.SET_TENANT_LANGUAGES,
        languages: getLanguagesState.data.languages,
      });
    }
  }, [getLanguagesState]);

  const [getLicensedFeaturesState, getLicensedFeaturesRequest] = useAxios(
    { url: '/license/v1/get-licensed-features', method: 'GET' },
    false
  );
  useEffect(() => {
    if (getLicensedFeaturesState.wasSuccessful) {
      tenantSettingsDispatch({
        type: tenantSettingsActions.SET_LICENSING,
        licenses: getLicensedFeaturesState.data,
      });
    }
  }, [getLicensedFeaturesState]);

  const [getTags, getTagsRequest] = useAxios(
    { url: '/web/api/tenant/v1/get-tags', method: 'GET' },
    false
  );
  useEffect(() => {
    if (getTags.wasSuccessful && getTags.data) {
      tenantSettingsDispatch({
        type: tenantSettingsActions.SET_TAGS,
        tags: getTags.data,
      });
    }
  }, [getTags]);

  const [getEnvironments, getEnvironmentsRequest] = useAxios(
    { url: '/web/api/tenant/v1/get-environments', method: 'GET' },
    false
  );
  useEffect(() => {
    let environments = {};
    if (getEnvironments.wasSuccessful && getEnvironments.data) {
      const { version, ...rest } = getEnvironments.data;
      environments = version ? rest : defaultEnvironments;
    } else {
      environments = defaultEnvironments;
    }
    tenantSettingsDispatch({
      type: tenantSettingsActions.SET_ENVIRONMENTS,
      environments,
    });
  }, [getEnvironments]);

  useEffect(() => {
    if (
      !companyMetadataState.called &&
      !companyMetadataState.loading &&
      companyMetadataDispatch.state.update
    ) {
      companyMetadataRequest();
    }
    if (
      !getLanguagesState.called &&
      !getLanguagesState.loading &&
      tenantSettingsState.languages.update
    ) {
      getLanguagesRequest();
    }
    if (
      !getLicensedFeaturesState.called &&
      !getLicensedFeaturesState.loading &&
      Object.keys(tenantSettingsState.licensing).length === 0
    ) {
      getLicensedFeaturesRequest();
    }
    if (!getTags.loading && !getTags.called) {
      getTagsRequest();
    }
    if (!getEnvironments.loading && !getEnvironments.called) {
      getEnvironmentsRequest();
    }
  }, [pathname]);

  return null;
};

export default ConfigurationLoader;
