import { useContext } from 'react';

import { CompanyMetadataContext } from '../contexts/companyMetadata';

const useCompanyMetadata = () => {
  const companyMetadata = useContext<any>(CompanyMetadataContext);

  return {
    companyMetadataState: companyMetadata.state,
  };
};

export default useCompanyMetadata;
