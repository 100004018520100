export const loadDataFromLocalStorage = (key: string, fallback: any) => {
  const item = localStorage.getItem(key);

  if (item) {
    try {
      return JSON.parse(item);
    } catch {
      return item;
    }
  } else {
    return fallback;
  }
};

export const saveDataToLocalStorage = (key: string, data: any) => {
  data instanceof Object
    ? localStorage.setItem(key, JSON.stringify(data))
    : localStorage.setItem(key, data);
};
