import makeStyles from '@material-ui/styles/makeStyles';

const useStyle = makeStyles((theme) => ({
  drawer: {
    width: 250,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 250,
    background: `linear-gradient(0deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 100%)`,
  },
  navigationList: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    height: 'calc(100% - 10rem)',
    overflow: 'auto',
    scrollbarWidth: 'none',  /* Firefox */
    '-ms-overflow-style': 'none',  /* Internet Explorer 10+ */
    '&::-webkit-scrollbar': {
      display: 'none',  /* Safari and Chrome */
    }
  },
  navigationButton: {
    paddingLeft: '5px',
    paddingBottom: '0px',
  },
  navigationLinks: {
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      color: '#FFC06D',
    },
  },
  nonClickableLinks: {
    color: 'white',
    textDecoration: 'none',
    cursor: 'pointer',
    opacity: '1 !important',
    '&:hover': {
      color: '#FFC06D',
    },
  },
  navigationListIcon: {
    minWidth: '30px',
  },
  navigationIcon: {
    width: '20px',
    maxHeight: '20px',
    marginRight: '15px',
  },
  subMenuItem: {
    paddingTop: '0px',
    paddingBottom: '-2px',
  },
  subMenuText: {
    fontSize: '1em',
  },
  snobalLogo: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(3.5),
    width: '190px',
    padding: theme.spacing(3),
    backgroundColor: `linear-gradient(0deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 100%)`,
  },
  toolbar: theme.mixins.toolbar,
}));

export default useStyle;
